import { 
  Button,
  Card, 
  CardContent, 
  FormControlLabel, 
  MenuItem, 
  Radio, 
  RadioGroup, 
  Select, 
  Typography 
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { EditSkeleton } from "components/admin/admin.overlays";
import InfoModal from "components/shared/infoModal/InfoModal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetResidentsAllQuery } from "store/apis/ResidentsApi";
import { setGuestInfo } from "store/slices/orderSlice/OrderSlice";
import "./Guest.css";
import { useForm } from "react-hook-form";
import BaseInput from "components/shared/baseForm/BaseInput";
import { REQUIRED_ERROR } from "utils/errorMessages";

export default function Guest() {
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all"
  });

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors }
  } = methods;

  const {
    data: residents,
    isFetching: fetchingResidents,
    isError: errorResidents,
    error: errorResidentMsg
  } = useGetResidentsAllQuery();

  const renderResidentList = () => {
    const residentUIList = [<MenuItem key={"none"} value={0}>None selected</MenuItem>];

    residents?.forEach((res) => {
      residentUIList.push(
        <MenuItem key={res.id} value={res.id}>{res.name?.split('(')?.[0]?.trim()}</MenuItem>
      )
    });

    return residentUIList;
  }

  useEffect(() => {
    if (!fetchingResidents && errorResidents) {
      setIsError(true);
      setModalText(errorResidentMsg?.data?.messages?.join("\n"));
      setOpen(true);
    }
  }, [fetchingResidents, errorResidents]);

  const residentGuestCheck = watch('selectedGuestType', 1);

  useEffect(() => {
    //reset selectedResident formValue when guest type is not resident guest
    if (residentGuestCheck !== 1) {
      setValue('selectedResident', 0);
    }
  }, [residentGuestCheck])

  const handleGuestSubmit = (data) => {
    const paymentType = Number.parseInt(data?.paymentDone);

    if (!data?.guestName?.trim()?.length || ( paymentType < 0 || isNaN(paymentType) )) {
      setModalText("You should enter the Guest/Employee Name and Payment.");
      setOpen(true);
    } else if (data?.selectedGuestType === 1 && data?.selectedResident === 0) {
      setModalText("Select Guest/Non-Resident Name for Guest Type");
      setOpen(true);
    } else {
      dispatch(setGuestInfo({
        ...data,
        paymentDone: Number.parseInt(data.paymentDone) //react hook form only supports implicit string conversions for radio values, cast back
      }));
      navigate("/takeorder/guest/order");
    }
  }

  const handleClose = () => {
    setIsError(false);
    setOpen(false);
  }

  const paymentGroup = [
    {value: 0, label: "No Charge"},
    {value: 1, label: "Payment Received"},
    {value: 2, label: "Bill to Resident"},
    {value: 3, label: "Bill to Employee"}
  ];

  return (
    <> { fetchingResidents ? (
      <EditSkeleton />
      )
      :
      (
        <Grid container justifyContent="center" alignItems="center" direction="column">
          <Card sx={{width: "98%", margin: '1%'}}>
            <CardContent>
              <BaseInput
                id="guestName"
                sx={{margin: "5px"}}
                control={control}
                errors={errors}
                name="guestName"
                type="text"
                placeholder="Guest/Employee Name"
                validationProps={{
                  required: REQUIRED_ERROR("Guest Name"),
                  maxLength: {value: 50, message: "Guest Name cannot be over 50 characters"}
                }}
                fullWidth
                variant="standard"
                inputProps={{style: {textAlign: "center"}}}
              />
              <Grid container justifyContent="center" alignItems="center" sx={{margin: "5px"}}>
                <RadioGroup 
                  control={control}
                  errors={errors}
                  row={true}
                >
                  {paymentGroup.map((payment) => {
                    return (
                    <FormControlLabel key={payment.value}
                      {...register("paymentDone", {valueAsNumber: true})}
                      value={payment.value}
                      control={<Radio />}
                      label={payment.label} />
                    )
                  })}
                </RadioGroup>
              </Grid>

              <Grid container justifyContent="center" alignItems="center" sx={{margin: "5px"}}>
                <Typography sx={{marginRight: "20px"}}>
                  Ticket Type:
                </Typography>
                <Select
                  {...register("selectedGuestType", {valueAsNumber: true})}
                  control={control}
                  errors={errors}
                  id="selectedGuestType"
                  name="selectedGuestType"
                  variant="standard"
                  defaultValue={1}
                >
                  <MenuItem key={1} value={1}>{"Guest"}</MenuItem>
                  <MenuItem key={2} value={2}>{"Employee"}</MenuItem>
                  <MenuItem key={3} value={3}>{"Other"}</MenuItem>
                </Select>

                {watch('selectedGuestType', 1) == 1 && (
                  <>
                    <Typography sx={{marginLeft: "50px", marginRight: "20px"}}>
                      Guest/Non-Resident of
                    </Typography>
                    <Select
                      {...register("selectedResident", {valueAsNumber: true})}
                      control={control}
                      errors={errors}
                      id="selectedResident"
                      name="selectedResident"
                      variant="standard"
                      defaultValue={0}
                    >
                      {renderResidentList()}
                    </Select>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Button
            variant="contained"
            color="primary"
            sx={{margin: "1%", width: "98%"}}
            onClick={handleSubmit(handleGuestSubmit)}
          >
            Take Order
          </Button>
          {open && (
            <InfoModal title={isError ? "Error" : "Alert"} open={open} modalText={modalText} handleClose={handleClose} />
          )}
        </Grid>
      )
    }
    </>
  )
}