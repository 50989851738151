import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AppBar, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material";
import { loginRequest } from "authConfig";
import React from "react";
import { Link } from "react-router-dom";
import useMsalAccount from "utils/useMsalAccount";

export default function SideDrawer({ open, toggleDrawer }) {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { account } = useMsalAccount();
  const ROUTES = [
    "/",
    "/takeorder/residents",
    "/meal",
  ];

  
  const handleLogout = async (event) => {
    toggleDrawer(event, false);
    if (isAuthenticated) {
      await instance.logoutRedirect({ account: account });
    } else {
      await instance.loginRedirect({...loginRequest, prompt: "login"})
    }
  };

  return (
    <Drawer open={open} onClose={() => toggleDrawer()}>
      <AppBar position="static">
        <Toolbar sx={{width: "100%"}}>
          <Typography variant="h6" color="#FFF" fontWeight="bold">
            Main Menu
          </Typography>
        </Toolbar>
      </AppBar>
      <List sx={{minWidth: "9rem !important"}}>
        {['Home', 'Take Orders'].map((text, index) => (  //, 'Start a Meal'
          <ListItem 
            sx={{height: "3rem"}}
            key={text} 
            disablePadding 
            component={Link} 
            to={ROUTES[index]}
            onClick={() => toggleDrawer()}>
            <ListItemButton>
              <ListItemText 
                disableTypography
                primary={
                <Typography variant="body" 
                color="#000" 
                fontSize="1.125rem" 
                textDecoration="none">{text}</Typography>} 
              />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem sx={{height: "3rem"}} disablePadding onClick={(event) => handleLogout(event)}>
          <ListItemButton>
            <ListItemText 
              disableTypography
              primary={
              <Typography 
                variant="body" 
                color="#000" 
                fontSize="1.125rem" 
                textDecoration="none">{isAuthenticated ? 'Logout' : 'Login'}</Typography>} 
            />          
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}